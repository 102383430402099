import { render, staticRenderFns } from "./RelatedProjects.vue?vue&type=template&id=719b3cd5&"
import script from "./RelatedProjects.vue?vue&type=script&lang=js&"
export * from "./RelatedProjects.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports